import React, {Fragment, useEffect} from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import Header from "../components/Header";

function Legal() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/legal.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Legal Disclaimer</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Legal Disclaimer</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">IMPORTANT LEGAL NOTICE</h5>
                                        <p className="text__block-desc">ATTENTION: This legal notice applies to the entire contents of the Website under the domain name www.transconglobal.co.za
                                            (Website) and to any correspondence by e-mail between us and you. Please read these terms carefully before using the Website. Using the Website
                                            indicates that you accept these terms regardless of whether or not you choose to register with us. If you do not accept these terms, do not use the Website.
                                            This notice is issued by Transcon Global (Company).</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h5>Issue Date: 30 April 2020</h5>
                                    <div id="accordion" className="mb-60">
                                        <div className="accordion-item">
                                            <div className="accordion__item-header" data-toggle="collapse"
                                                 data-target="#collapse1">
                                                <a className="accordion__item-title" href="#">1. License</a>
                                            </div>
                                            <div id="collapse1" className="collapse" data-parent="#accordion">
                                                <div className="accordion__item-body">
                                                    <p>1.1 You are permitted to print and download extracts from the Website for your own use on the following basis:</p>
                                                    <ul>
                                                        <li>(a) no documents or related graphics on the Website are modified in any way;</li>
                                                        <li>(b) no graphics on the Website are used separately from the corresponding text; and</li>
                                                        <li>(c) the Company’s copyright and trade mark notices and this permission notice appear in all copies.</li>
                                                    </ul>
                                                    <br/>
                                                    <p>1.2 Unless otherwise stated, the copyright and other intellectual property rights in all material on the Website
                                                        (including without limitation photographs and graphical images) are owned by the Company or its licensors. For the
                                                        purposes of this legal notice, any use of extracts from the Website other than in accordance with clause 1.1 for any
                                                        purpose is prohibited. If you breach any of the terms in this legal notice, your permission to use the Website automatically
                                                        terminates and you must immediately destroy any downloaded or printed extracts from the Website.
                                                    </p>
                                                    <br/>
                                                    <p>1.3 Subject to clause 1.1, no part of the Website may be reproduced or stored in any other website or included in any public or
                                                        private electronic retrieval system or service without the Company’s prior written permission.
                                                    </p>
                                                    <br/>
                                                    <p>
                                                        1.4 Any rights not expressly granted in these terms are reserved.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion__item-header" data-toggle="collapse"
                                                 data-target="#collapse2">
                                                <a className="accordion__item-title" href="#">2. Visitor Material & Conduct</a>
                                            </div>
                                            <div id="collapse2" className="collapse" data-parent="#accordion">
                                                <div className="accordion__item-body">
                                                    <p>2.1 Other than personally identifiable information, which is covered under our Privacy Policy, any material you transmit or post to the
                                                        Website shall be considered non-confidential and non-proprietary. The Company shall have no obligations with respect to such material.
                                                        The Company and its designees shall be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images,
                                                        sounds, text and other things embodied therein for any and all commercial or non-commercial purposes.
                                                    </p>
                                                    <br/>
                                                    <p>2.2 You are prohibited from posting or transmitting to or from the Website any material:</p>
                                                    <ul>
                                                        <li>(a) that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred,
                                                            discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; or
                                                        </li>
                                                        <li>(b) for which you have not obtained all necessary licenses and/or approvals; or</li>
                                                        <li>(c) which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in any country in the world; or</li>
                                                        <li>(d) which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li>
                                                    </ul>
                                                    <br/>
                                                    <p>2.3 You may not misuse the Website (including, without limitation, by hacking).</p>
                                                    <br/>
                                                    <p>2.4 The Company shall fully co-operate with any law enforcement authorities or court order requesting or directing the Company to disclose the identity or locate anyone posting any material in breach of clause 2.2 or clause 2.3.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion__item-header" data-toggle="collapse"
                                                 data-target="#collapse3">
                                                <a className="accordion__item-title" href="#">3. Disclaimer</a>
                                            </div>

                                            <div id="collapse3" className="collapse" data-parent="#accordion">
                                                <div className="accordion__item-body">
                                                    <p>3.1 While the Company endeavours to ensure that the information on the Website is correct, the Company does not warrant the accuracy and completeness of the material on the Website. The Company may make changes to the material on the Website, or
                                                        to the products and prices described in it, at any time without notice. The material on the Website may be out of date, and the Company makes no commitment to update such material.</p>
                                                    <br/>
                                                    <p>3.2 The material on the Website is provided “as is”, without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, the Company provides you with the Website on the basis that the Company
                                                        excludes all representations, warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which, but for this legal notice, might have effect in relation to the Website.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion__item-header" data-toggle="collapse"
                                                 data-target="#collapse4">
                                                <a className="accordion__item-title" href="#">4. Liability</a>
                                            </div>

                                            <div id="collapse4" className="collapse" data-parent="#accordion">
                                                <div className="accordion__item-body">
                                                    <p>4.1 The Company and any of the Company’s group companies and the officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including without limitation, any direct,
                                                        indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including without limitation negligence), contract or otherwise) in connection
                                                        with the Website in any way or in connection with the use, inability to use or the results of use of the Website, any websites linked to the Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property
                                                        on account of your access to, use of, or browsing the Website or your downloading of any material from the Website or any websites linked to the Website.
                                                    </p>
                                                    <br/>
                                                    <p>4.2 Nothing in this legal notice shall exclude or limit the Company’s liability for:</p>
                                                    <ul>
                                                        <li>(a) death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977; or</li>
                                                        <li>(b) fraud; or</li>
                                                        <li>(c) misrepresentation as to a fundamental matter; or</li>
                                                        <li>(d) any liability which cannot be excluded or limited under applicable law.</li>
                                                    </ul>
                                                    <br/>
                                                    <p>4.3 If your use of material on the Website results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <div className="accordion__item-header" data-toggle="collapse"
                                                 data-target="#collapse5">
                                                <a className="accordion__item-title" href="#">5. Governing law and jurisdiction</a>
                                            </div>

                                            <div id="collapse5" className="collapse" data-parent="#accordion">
                                                <div className="accordion__item-body">
                                                    <p>This legal notice shall be governed by and construed in accordance with Zambian law. Disputes arising in connection with this legal notice shall be subject to the exclusive jurisdiction of the Zambian courts.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Legal;
