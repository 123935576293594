import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <Fragment>
            <footer id="footer" className="footer footer-1">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-4 footer__widget footer__widget-about  mb-30">
                                <div className="footer__widget-content">
                                    <img src="assets/images/logo/logo-dark.png" className="logo-light" alt="logo" height="70"/>
                                    <p><a href="mailto:info@transconglobal.africa" className="color-white">info@transconglobal.africa</a></p>
                                    <span className="color-white"><strong>South Africa Trading Office</strong></span>
                                    <p>Building-Atrium on 5th street, <br/>Floor- 9th floor, Street 5th Street, <br/>+27 (0) 10 005 2331</p>
                                    <span className="color-white"><strong>Zambia Head Office</strong></span>
                                    <p>Head Office No 9, Elm Road, <br/>Woodlands Lusaka, <br/>+260 977 772 165 </p>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
                                <h6 className="footer__widget-title">
                                    <Link to="who-we-are">Who We Are</Link>
                                </h6>
                                <div className="footer__widget-content">
                                    <nav>
                                        <ul className="list-unstyled">
                                            <li><Link to="our-company">Our company</Link></li>
                                            <li><Link to="our-history">Our History</Link></li>
                                            <li><Link to="our-desire-focus">Our Desire & Focus</Link></li>
                                            <li><Link to="our-vision-mission">Our Vision & Mission</Link></li>
                                            <li><Link to="our-business-model">Our Business Model</Link></li>
                                            <li><Link to="our-capacity">Our Capacity</Link></li>
                                            <li><Link to="working-with-us">Working with us</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
                                <h6 className="footer__widget-title">
                                    <Link to="what-we-do">What We Do</Link>
                                </h6>
                                <div className="footer__widget-content">
                                    <nav>
                                        <ul className="list-unstyled">
                                            <li><Link to="trading">Trading</Link></li>
                                            <li><Link to="mining">Mining</Link></li>
                                            <li><Link to="renewable-energy">Renewable Energy</Link></li>
                                            <li><Link to="logistics">Logistics</Link></li>
                                            <li><Link to="agriculture">Agriculture</Link></li>
                                            <li><Link to="our-supply-chain">Our Supply Chain</Link></li>
                                            <li><Link to="sustainability">Sustainability</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div
                                className="col-12 col-sm-12 col-md-6 col-lg-4 footer__widget footer__widget-newsletter">
                                <div className="footer__widget-content">
                                    <h6 className="footer__widget-title">Newsletter</h6>
                                    <p>Sign up for industry alerts, our latest news, thoughts, and insights from
                                        Transcon Global.</p>
                                    <form className="widget__newsletter-form">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control"
                                                   placeholder="Your Email Address "/>
                                            <button type="submit" className="btn btn__primary btn__hover2">Subscribe
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="footer__copyright">
                                    <nav>
                                        <ul className="list-unstyled footer__copyright-links d-flex flex-wrap">
                                            <li><a href="#">© 2024 Transcon Global.</a></li>
                                            <li><Link to="legal-disclaimer">Legal Disclaimer </Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center">
                                <div className="social__icons justify-content-end w-100"><a
                                    href="https://www.facebook.com/transconpty"><i className="fa fa-facebook"></i></a><a
                                    href="https://twitter.com/Transconglobal?s=09"><i className="fa fa-twitter"></i></a><a
                                    href="https://www.linkedin.com/company/transcon-global"><i
                                    className="fa fa-linkedin"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <button id="scrollTopBtn"><i className="fa fa-long-arrow-up"></i></button>
        </Fragment>
    );
}

export default Footer;
