import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurDesireFocus() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/maize.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Desire & Focus</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Desire & Focus</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Desire</h5>
                                        <p className="text__block-desc">To use trade in less mature and developing
                                            markets to help uplift living standards and empower all those living there.
                                            We know we are more than a business; we are part of society. As a company ,
                                            we have a social and economical responsibility to contribute to developing
                                            opportunities for people and improving the life of the communities in which
                                            we are active. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Focus</h5>
                                        <p className="text__block-desc">We want to focus on advancing reliable trade in
                                            an open and transparent manner in less mature and developed markets as we
                                            see trade as a powerful and effective tool in uplifting millions of lives in
                                            our core markets, however one life at a time.
                                            We are able to source commodities from remote and under developed locations
                                            and effectively deliver to markets of demand, thereby adding economical
                                            value to both the places of supply and demand.
                                            We value each and very producer irrespective of size and standing in the
                                            market and society. We believe in extensive networking and sharing of
                                            information with both our suppliers and customers, evenly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default OurDesireFocus;
