import React, {Fragment, useEffect} from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Header from "../components/Header";

function WhoWeAre() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/global.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Who We Are</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">who we are</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">Our Company</h5>
                                        <p className="text__block-desc">With our head office in Zambia and a Trading office
                                            in South Africa, we are a young, dynamic and resilient commodity trading company
                                            working with small-scale producers and processors. With our team of experts, we
                                            import from a place of surplus, however remote and export to a place of demand.
                                            We are prepared to take all risks involved in global trade on behalf of our suppliers
                                            and buyers thereby eliminating all complexities and anxieties associated with global trade.
                                            <Link to="/our-company">Read More</Link>
                                        </p>

                                        <h5 className="text__block-title">History</h5>
                                        <p className="text__block-desc">Transcon Global was formed in February of 2015
                                            prompted by our desire to export beef to
                                            Angola. Our extensive feasibility study of the market revealed an enormous
                                            lack of quality in beef products our
                                            competitors offered. Angola was a tough market for a new entrant and
                                            Transcon Global was not spared from challenges
                                            and risks that come with commodity trading. From Forex, to complicated
                                            customs procedures and language barrier,
                                            Transcon rose above the... <Link to="/our-history">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: 30}}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                         data-slide="2" data-slide-md="2"
                                         data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="false"
                                         data-space="30"
                                         data-loop="true" data-speed="800">
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/1.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/2.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/3.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/4.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/5.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/6.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/7.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/8.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/9.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/10.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/11.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/12.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/13.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/14.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Desire</h5>
                                        <p className="text__block-desc">To use trade in less mature and developing
                                            markets to help uplift living standards and empower all those living there.
                                            We know we are more than a business;
                                            we are part of society. As a company , we have a social and economical
                                            responsibility to contribute to developing opportunities for people and
                                            improving the life of the communities in which we are active. <Link to="/our-desire-focus">Read More</Link></p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Focus</h5>
                                        <p className="text__block-desc">We want to focus on advancing reliable trade in
                                            an open and transparent manner in less mature and developed markets as we
                                            see trade as a powerful and effective tool in uplifting millions of lives in
                                            our core markets, however one life at a time.
                                            We are able to source commodities from remote and under developed locations
                                            and effectively deliver to markets of demand... <Link to="/our-desire-focus">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Business Model</h5>
                                        <p className="text__block-desc">Our business development model is based on
                                            forging long term partnerships with small scale producers and processors
                                            with the mutual aim of uplifting each other. It is based on mutual benefit,
                                            leverage and trust. Our physical presence in remote areas helps to unlock
                                            potential thereby greatly contributing to our supply chain. We sit around
                                            the table with our small scale producers and allow...<Link to="/our-business-model">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Edge over Competitors</h5>
                                        <p className="text__block-desc">Our ability to gather information from remote
                                            supply markets gives us an edge over our competitors. While many large
                                            commodity-trading companies view their size as a barrier to entry for
                                            smaller trading companies, we view our size as an advantage over them as we
                                            are capable of reaching and penetrating remote areas they cannot. <Link to="/our-edge-over-competitor">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Vision</h5>
                                        <p className="text__block-desc">To be a highly ethical, fair, transparent and
                                            efficient commodity supply global partner and continually exceed market
                                            expectations with premium quality products. We aim to achieve our vision by
                                            establishing a wide network of supply and demand base as well as adopting
                                            highly efficient distribution methods, modern storage techniques and
                                            practices without compromising <Link to="/our-vision-mission">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Mission</h5>
                                        <p className="text__block-desc">At Transcon Global, we are always looking ahead.
                                            We understand most of the challenges and risks involved in commodity
                                            trading. We aim to achieve this by obtaining grass-root information both
                                            from the places of supply, however remote, and demand and using this
                                            information in uplifting standards of living for our peasant producers. <Link to="/our-vision-mission">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Capacity</h5>
                                        <p className="text__block-desc">We also do not see ourselves as the way you see
                                            us. We do not gauge ourselves by what we are today but by what we want to
                                            become tomorrow. Hence we are constantly working hard to satisfy the needs
                                            of both our producers/Processors and customers. Our capacity is not measured
                                            by the size or number of offices, globally but by the vast global knowledge
                                            of the commodity trading industry and our desire to become world class
                                            trading partners. <Link to="/our-capacity">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="video-1 mb-60">
                                        <div className="bg-img"><img src="assets/images/video/1.jpg" alt="background"/>
                                        </div>
                                    </div>

                                    <div className="text__block">
                                        <h5 className="text__block-title">Working with us</h5>
                                        <p className="text__block-desc">We have a strong, experienced and proactive
                                            management team whose entrepreneurial skills, culture, passion and
                                            commitment have been
                                            guiding us through tremendous growth. Our management team has earned an
                                            enormous amount of knowledge from both past failures and successes,
                                            especially failures. <Link to="pages/working-with-us">Read More</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default WhoWeAre;
