import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function RenewableEnergy() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/renewable.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Renewable Energy</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/what-we-do">what we do</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Renewable Energy</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar mb-30">
                                <div className="widget widget-categories">
                                    <h5 className="widget__title">What We Do</h5>
                                    <div className="widget-content">
                                        <ul className="list-unstyled">
                                            <li><Link to="/trading">Trading</Link></li>
                                            <li><Link to="/mining">Mining</Link></li>
                                            <li><Link to="/renewable-energy" className="active">Renewable Energy</Link></li>
                                            <li><Link to="/logistics">Logistics</Link></li>
                                            <li><Link to="/agriculture">Agriculture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">We Are Determined To Be An Active Participant In The Energy Transition As The World Evolves From Its Dependency Of Fossil Fuels To Sustainable Renewable Energies.</h5>
                                        <p className="text__block-desc">We are determined to be an active participant in the energy transition as the world evolves from its dependency of fossil fuels to sustainable renewable energies.</p>
                                        <p className="text__block-desc">However, we understand that the intermittent nature of renewable energy sources such as solar and wind require a complementary source of power generation to ensure the reliable supply of power. As such a sustainable transition must be supported by a sustainable energy mix. </p>
                                        <p className="text__block-desc">Our strategy is to become a Pan African Independent Power Producer, IPP, with capabilities to develop, own, procure, construct and operate renewable energy power projects, from commercial roof top PV systems, ground-mounted minigrids, and containerized solutions to Utility scale power plants. We are working together with our extensive partner network spread across the globe to implement renewable energy projects economically and reliably, thereby saving not only the scarce capital in Africa but also the environment for the greater good of humanity.</p>
                                        <h6 className="text__block-title" style={{fontStyle: 'italic'}}>Origination</h6>
                                        <p className="text__block-desc">We have the ability to originate, structure and advise on clean energy projects in any part of Africa. With our extensive network of financiers, we look for opportunities in different countries at any stage of development, even when the idea is only on paper, and turn them into successful projects. We are able to offer feasibility studies, design, engineering, procurement and construction services from zero.</p>
                                        <h6 className="text__block-title" style={{fontStyle: 'italic'}}>Funding</h6>
                                        <p className="text__block-desc">We have established a wide network of funding partners who are able to raise and provide capital – both in debt and equity – for investment in renewable energy projects. These partners have a track record of having supported the financing of projects for a different category of clients from private owned businesses to parastatals.</p>
                                        <h6 className="text__block-title" style={{fontStyle: 'italic'}}>Biodiesel</h6>
                                        <p className="text__block-desc">We believe that Zambia can become a large supplier of bioethanol. Its near-central geographical location, suitable climate, abundance in land and water provide a strong case for successful production within the country. Identified potential bioethanol crops such sugarcane, Sweet Sorghum and cassava, are grown by a number of small-scale farmers, albeit, in large volumes for own consumption by locals in the Southern and Northern parts of the country. Transcon Global intends to establish a bioethanol plant in 2021 to produce ethanol for local and export consumption.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: 30}}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                         data-slide="2" data-slide-md="2"
                                         data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="false"
                                         data-space="30"
                                         data-loop="true" data-speed="800">
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/30.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/31.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/32.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/33.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/34.jpg" alt="project img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default RenewableEnergy;
