import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function Agriculture() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/agriculture.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Agriculture</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/what-we-do">what we do</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Agriculture</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar mb-30">
                                <div className="widget widget-categories">
                                    <h5 className="widget__title">What We Do</h5>
                                    <div className="widget-content">
                                        <ul className="list-unstyled">
                                            <li><Link to="/trading">Trading</Link></li>
                                            <li><Link to="/mining">Mining</Link></li>
                                            <li><Link to="/renewable-energy">Renewable Energy</Link></li>
                                            <li><Link to="/logistics">Logistics</Link></li>
                                            <li><Link to="/agriculture" className="active">Agriculture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">we connect small-scale producers and big end
                                            users of agricultural commodities around the world through origination, and
                                            trading.</h5>
                                        <p className="text__block-desc">Agriculture is beyond planting and growing of
                                            crops. It also involves processing, storage, financing, trading, packaging,
                                            branding even
                                            advertising. Our activities around agriculture are centered on a clear
                                            understanding of small scale value chain. We source soft commodities for
                                            human and animal consumption
                                            from surplus markets and sell to demand markets. </p>
                                        <p className="text__block-desc">We have a team of experts that identifies and
                                            selects small-scale farmers who are contracted to the company on an
                                            outgrower scheme. We empower these
                                            small-scale farmers through the provision of farming inputs as well as a
                                            viable ready market with a clearly outlined value chain. In so doing, we
                                            connect small-scale producers
                                            to big end users of agricultural commodities around the world through
                                            origination, and trading. Many factors limit the ability of small-scale
                                            farmers to boost their productivity
                                            and make the transition from subsistence farming to market-oriented
                                            production. Our Outgrower Schemes are therefore designed to increase yields
                                            and maximize profitability thereby uplifting lives in areas we operate.</p>
                                        <p className="text__block-desc">We also own farmland in Central Zambia where we
                                            are growing Soybeans for local consumption and export.
                                            We are also in the process of setting up plantations in the Northern part of
                                            Zambia for palm oil. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: 30}}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                         data-slide="2" data-slide-md="2"
                                         data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="false"
                                         data-space="30"
                                         data-loop="true" data-speed="800">
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/17.jpg" alt="project img"/>
                                            </div>

                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/18.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/19.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>

                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/21.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/22.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/2.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/3.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/4.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Agriculture;
