import React, { Fragment, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import Header from "../components/Header";

function Loknvar() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header />
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/loknvar.jpg" alt="background" /></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">LokNVar</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">subsidiaries</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="text__block mb-40">
                                <h5 className="text__block-title" style={{ marginBottom: 2 }}>LokNVar</h5>
                                <p className="text__block-desc">LokNVar dairy and meat products is a registered trademark for our dairy, poultry, and meat products.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{ marginBottom: 2 }}>LokNVar Beef Products</h6>
                                <p className="text__block-desc">Beef is a crucial part of a healthy, nutritional diet. It is a rich source of protein and contains the nine essential amino acids that the human body cannot produce. Protein serves as the building blocks for the body's muscles, connective tissue, bones, brain, blood, skin, hair, and nails. Your immune system is also dependent on protein.</p>
                                <p className="text__block-desc">Red meat contains Vitamin B complex (thiamine, riboflavin, niacin), Vitamin B6, and Vitamin B12 and is also an excellent source of minerals like iron, zinc, phosphorus, potassium, copper, magnesium, and manganese.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{marginBottom: 2}}>The 15 Choices Processed,
                                    Packaged, and Marketed from Our Grass-Fed Cow:</h6>
                                <ul className="text__block-list">
                                    <li>
                                        <p className="text__block-desc"><strong>Chuck:</strong> Contains six vertebrae
                                            and six to seven rib bones. It also consists of several muscle layers.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Neck:</strong> Contains a large
                                            proportion of bone and white connective tissue, making it very tasty.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Bolo Blade:</strong> A bright-red muscle
                                            layer on top resembles a red patch. Bolo contains little intramuscular fat
                                            and is a boneless beef cut with a coarse texture.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Brisket:</strong> Contains a breast bone
                                            and a few ribs. It is divided into the point brisket, mid-brisket, and navel
                                            end.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Shin:</strong> Contains a large
                                            proportion of bone and white connective tissue.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Short Ribs:</strong> Variants: Full 8
                                            Rib (Cap on/Cap off), 4 Rib (Cap on/Cap off).</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Prime Rib:</strong> Consists of three to
                                            four rib bones and an eye muscle, with several flat muscle layers on top.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Thin Flank:</strong> Contains a few ribs
                                            in a triangular shape (short rib) with the rest of the beef cut boneless and
                                            covered with a thick layer of connective tissue.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Ribeye U:</strong> Variants: OP Ribs,
                                            Cuberoll.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Sirloin:</strong> The striploin
                                            (sirloin) is attached and runs parallel to the spine on the hindquarter,
                                            inclusive of the eye muscle. The meat is tender and has a fine texture. As a
                                            bone-in cut, it includes the spinal bones, thus the club steak and T-bone
                                            (including fillet) can be derived from it.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Rump:</strong> A tender beef cut that
                                            consists of a few loosely connected muscles and the pelvic bone. This cut
                                            also contains part of the fillet.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Fillet:</strong> Situated inside the
                                            carcass alongside the vertebral column, from the wing rib to the rump. The
                                            fillet is boneless, without fat, and the most tender cut in the carcass.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Topside:</strong> Recognized by the
                                            half-moon-shaped fat layer on the outside. The texture is coarse with little
                                            marbling. The aitchbone is in the topside as well.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Silverside:</strong> Consists of three
                                            parallel muscles covered with a silvery layer of connective tissue. The meat
                                            has a coarse texture with very little intramuscular fat.</p>
                                    </li>
                                    <li>
                                        <p className="text__block-desc"><strong>Knuckle:</strong> Consists of three
                                            concentric muscles, the inside one being the most tender and known as mock
                                            fillet.</p>
                                    </li>
                                </ul>

                                <p className="text__block-desc mt-20">We also offer red and green offal.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{marginBottom: 2}}>Our Long-Term Vision</h6>
                                <p className="text__block-desc">The long-term vision for the LokNVar brand includes the
                                    establishment of an integrated facility housing an abattoir and milk collection
                                    center in the Southern Province of Zambia. This facility will be supported by
                                    peripheral activities such as milk collection points, a feedlot, an organic tannery,
                                    veterinary supply services, and a feed processing plant.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{marginBottom: 2}}>LokNVar Desire</h6>
                                <p className="text__block-desc">We aim to use trade and our presence in the areas we
                                    operate to help uplift living standards and empower small-scale livestock farmers.
                                    We know we are more than a business; we are part of communities and society at
                                    large. As a brand, we have a social and economic responsibility to contribute to
                                    developing opportunities for people and improving the lives of the communities in
                                    which we are active.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{marginBottom: 2}}>Our LokNVar Vision</h6>
                                <p className="text__block-desc">To be a highly ethical, fair, transparent, and efficient
                                    supply chain brand and continually exceed market expectations with premium quality
                                    products. We aim to achieve our vision on the supply side by establishing our
                                    presence in remote areas and recruiting and retaining a wide network of small-scale
                                    livestock farmers. On the demand side, by adopting highly efficient processing and
                                    distribution methods, modern storage techniques and practices without compromising
                                    internationally accepted standards of hygiene and quality, and lastly by placing
                                    progress in front of profits.</p>
                            </div>
                            <div className="text__block mb-40">
                                <h6 className="text__block-title" style={{marginBottom: 2}}>Our LokNVar Mission</h6>
                                <p className="text__block-desc">To become one of the largest, most reliable, honest, and
                                    ethical brands by according our small-scale livestock farmers a platform to grow and
                                    expand their reach for their greater economic benefits and sustainable growth in the
                                    long term.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Loknvar;
