import React, {Fragment, useEffect} from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Header from "../components/Header";

function Sustainability() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/trees.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Sustainability</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Sustainability</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">As a company working in the remotest parts of
                                            Africa, we take our responsibility towards health, safety and the
                                            environment extremely seriously</h5>
                                        <p className="text__block-desc">We desire to uplift living standards, create
                                            economic value and contribute to healthy ecosystems and strong communities.
                                            In other words, we do not just think about now, we focus on creating
                                            opportunities that meet the needs of our present without compromising the
                                            ability of future generations to meet their own needs.</p>
                                        <p className="text__block-desc">As we source products from small scale farmers
                                            or miners or develop projects from zero to bring electricity to even the
                                            most remote areas of Africa where it is needed the most,
                                            our environment remains at the centre of our decision making. .</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <p className="text__block-desc">As a company working in the remotest parts of
                                            Africa, we take our responsibility towards health, safety and the
                                            environment extremely seriously. Furthermore, we are aware that our
                                            business activities such as mining could impact on innocent communities in
                                            which we operate. We therefore seek to mitigate the impact and risks where
                                            possible.</p>
                                        <h6>Child Labour &amp; Forced Labour</h6>
                                        <p>Child Labour and Forced Labour are a major problem especially in the
                                            Artisanal Small-Scale Mining, ASM sector. </p>
                                        <p>As a significant player in the sourcing of commodities from remote and
                                            impoverished communities, we are committed to the effective abolition of all
                                            forms of Child Labour and Forced Labour in accordance with the principles of
                                            the International Labour Organisation (“ILO”) conventions.</p>
                                        <p>This commitment is enshrined in our Code of Conduct and Business Ethics. We
                                            recognise that Child Labour and Forced Labour remain significant problems in
                                            Africa and that government capacity to address these may be limited.
                                            However, despite the difficult nature of the problem, we are
                                            committed to playing a positive role in helping to eliminate exploitative
                                            labour practices, by working cooperatively with others, including our small
                                            scale producers to address abuses that may exist in our supply chains.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Sustainability;
