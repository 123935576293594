import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function Logistics() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/logistics.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Logistics</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/what-we-do">what we do</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Logistics</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar mb-30">
                                <div className="widget widget-categories">
                                    <h5 className="widget__title">What We Do</h5>
                                    <div className="widget-content">
                                        <ul className="list-unstyled">
                                            <li><Link to="/trading">Trading</Link></li>
                                            <li><Link to="/mining">Mining</Link></li>
                                            <li><Link to="/renewable-energy">Renewable Energy</Link></li>
                                            <li><Link to="/logistics" className="active">Logistics</Link></li>
                                            <li><Link to="/agriculture">Agriculture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">From loading, customs clearance to shipping,
                                            our goal is to optimise our customer’s overall supply chain
                                            effectiveness.</h5>
                                        <p className="text__block-desc">We cannot move physical commodities from where
                                            they are produced to where they are consumed without an effective Logistics
                                            and distribution
                                            system, especially in Africa where there is a scarcity of infrastructure.
                                            Logistics and distribution are therefore at the core of our business. From
                                            loading, customs
                                            clearance to shipping, our goal is to optimise our customer’s overall supply
                                            chain effectiveness by minimising risk and maximising efficiency, ultimately
                                            resulting in
                                            cost savings. </p>
                                        <p className="text__block-desc">We have a huge database of transporters we
                                            subcontract for movement of our commodities as well as on behalf of our
                                            customers. We have facilities
                                            with big shipping lines that we leverage for the benefit of out
                                            customers. </p>
                                    </div>

                                    <div className="row" style={{marginBottom: 30}}>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                                 data-slide="2" data-slide-md="2"
                                                 data-slide-sm="1" data-autoplay="true" data-nav="true"
                                                 data-dots="false" data-space="30"
                                                 data-loop="true" data-speed="800">
                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/13.jpg" alt="project img"/>
                                                    </div>
                                                </div>

                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/14.jpg" alt="project img"/>
                                                    </div>
                                                </div>

                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/15.jpg" alt="project img"/>
                                                    </div>
                                                </div>

                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/24.jpg" alt="project img"/>
                                                    </div>
                                                </div>

                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/25.jpg" alt="project img"/>
                                                    </div>
                                                </div>

                                                <div className="project-item">
                                                    <div className="project__img">
                                                        <img src="assets/images/case-studies/grid/26.jpg" alt="project img"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Logistics;
