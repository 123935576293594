import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function Mining() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/mining.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Mining</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/what-we-do">what we do</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Mining</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar mb-30">
                                <div className="widget widget-categories">
                                    <h5 className="widget__title">What We Do</h5>
                                    <div className="widget-content">
                                        <ul className="list-unstyled">
                                            <li><Link to="/trading">Trading</Link></li>
                                            <li><Link to="/mining" className="active">Mining</Link></li>
                                            <li><Link to="/renewable-energy">Renewable Energy</Link></li>
                                            <li><Link to="/logistics">Logistics</Link></li>
                                            <li><Link to="/agriculture">Agriculture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">Our Focus Is On The Artisanal Small-Scale Mining, ASM Sector, Which Is Mostly Done On A Small Scale,
                                            Locally Driven, Has Low Barriers To Entry, And Involves Different Types Of Minerals Beyond Copper.
                                        </h5>
                                        <p className="text__block-desc">In Zambia, the extraction of copper has dominated the economy. The firms that have controlled the sector
                                            over the years have been large-scale, mostly foreign.</p>
                                        <p className="text__block-desc">Our focus is on the <strong>Artisanal Small-Scale Mining</strong>, ASM sector, which is mostly done
                                            on a small scale, locally driven, has low barriers to entry, and involves different types of minerals beyond copper.</p>
                                        <p className="text__block-desc">We have been forging robust relationships with small-scale mines that are producing a wide variety of minerals
                                            such as Copper, Manganese, Lead, Zinc, Nickel, Gold and Gemstones -emerald, aquamarine, tourmaline, and amethyst. In most markets we operate in,
                                            ASM is the most important rural activity for non-urban Africans, Outside of agriculture. In some cases, we provide equipment to productive small-scale
                                            mines and sign exclusive off-take agreements. This offers mutual benefits to both parties and in so doing; we uplift and empower lives while contributing
                                            to global trade. We trade in Precious and Semi-Precious Minerals, ores & concentrates, primary metals, alloys and scraps. We obtain the required raw materials
                                            from ASM and large scale ongoing mining operations, processing companies, deposits and other sources.
                                        </p>
                                        <p>From off-take agreements to spot transactions – we offer our customers individual, tailored solutions for sourcing minerals.</p>
                                        <br/>
                                        <h6 className="text__block-title">Prospection</h6>
                                        <p>We Are Always On The Lookout For New Opportunities</p>
                                        <p>Our aim is to continue developing and growing as a company. Our strategy is based on searching for new or underdeveloped sources  in remote areas and subsequently develop
                                            them into operational mines and produce our own raw materials. There are many such underdeveloped sources in Zambia and Democratic Republic of Congo,  DRC in the hands of
                                            small and large scale exploration licence holders.
                                        </p>
                                        <p>The exploration of new or underdeveloped raw material deposits is the first step in the raw materials value chain but also requires huge investments . In the preliminary exploration
                                            phase, we engage  experts who together with us identify potential raw material sources on the basis of extensive research. Through professional prospecting we can predict the volume
                                            and content of the source. The next phase is to engage investors to develop the raw material sources under a mutually beneficial arrangement.
                                        </p>
                                        <p>As we  continue to support our customers with sourcing of mineral resources, we are also looking for mining opportunities in which we are able invest through our partners.</p>
                                    </div>
                                    <div className="video-1 mb-60">
                                        <div className="bg-img"><img src="assets/images/video/2.jpg" alt="background"/></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="features-list features-list-layout3 mb-30">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <p className="text__block-desc">We are licensed to trade in the following:</p>
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Ores & Concentrates</h4>
                                                        <p className="feature__desc">Copper, Alumina, Zinc, Lead, Chrome, Iron Ore</p>
                                                    </div>
                                                </div>

                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Primary Metals</h4>
                                                        <p className="feature__desc">Lead, Zinc, Manganese, Aluminium, Tin, Nickel, Copper, Cadmium, Cobalt, Chrome in Ingot- both LME approved and Non-LME. </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Precious and Semi-Precious
                                                            Minerals</h4>
                                                        <p className="feature__desc">Gold, Silver, Gemstones -Emerald, Aquamarine, Tourmaline, and Amethyst.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: 30}}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                         data-slide="2" data-slide-md="2"
                                         data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="false"
                                         data-space="30"
                                         data-loop="true" data-speed="800">
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/1.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/7.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/8.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/9.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/10.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/11.jpg" alt="project img"/>
                                                    <a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/23.jpg" alt="project img"/><a href="#" className="zoom__icon"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Mining;
