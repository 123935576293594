import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function OurBusinessModel() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/business.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Business Model</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/who-we-are">who we are</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Business Model</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">Our Business Model</h5>
                                        <p className="text__block-desc">Our business development model is based on
                                            forging long term partnerships with small scale producers and processors
                                            with the mutual aim of uplifting each other. It is based on mutual benefit,
                                            leverage and trust. </p>
                                        <p className="text__block-desc">Our physical presence in remote areas helps to
                                            unlock potential thereby greatly contributing to our supply chain. We sit
                                            around the table with our small scale producers and allow them to ask us all
                                            the difficult questions, thereby creating an enabling environment for our
                                            mutual growth and benefit. </p>
                                        <p className="text__block-desc">These relationships have played a key role in
                                            our business to date and will undoubtedly continue to do so as we navigate
                                            through the complex world of commodity trading.</p>
                                        <p className="text__block-desc">Our business development team is led by experts
                                            with extensive knowledge in sourcing and originating from remote areas.
                                            Their experience in identifying opportunities is fundamental to the
                                            sustainability of our model.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Fragment>
    );
}

export default OurBusinessModel;
