import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurCompany() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/plant.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Company</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">Who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Company</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">Our Company</h5>
                                        <p className="text__block-desc">With our head office in Zambia and Trading offices in South Africa and the United Arab Emirates, we are a young, dynamic and resilient commodity trading company working with small-scale producers and processors. With our team of experts, we import from a place of surplus, however remote and export to a place of demand. We are prepared to take all risks involved in global trade on behalf of our suppliers and buyers thereby eliminating all complexities and anxieties associated with global trade.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Fragment>
    );
}

export default OurCompany;
