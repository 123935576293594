import React, {Fragment, useEffect} from 'react';
import { Link } from "react-router-dom";
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import Header from "../components/Header";

function Home() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <Slider/>

            <section id="about2" className="about about-2 pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-xl-1 ">
                            <div className="heading heading-3 mb-50"><a href="who-we-are.html"
                                                                        className="heading__subtitle">Who we are</a><h2
                                className="heading__title">We are a young, dynamic and resilient commodity trading
                                company.</h2></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="service__desc mt-30"><p>With our head office in Zambia and a Trading office
                                in South Africa, we are a young, dynamic and resilient commodity trading company working
                                with small-scale producers and processors. With our team of experts, we import from a
                                place of surplus, however remote and export to a place of demand. We are prepared to
                                take all risks involved in global trade on behalf of our suppliers and buyers thereby
                                eliminating all complexities and anxieties associated with global trade. </p></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/9.jpg" alt="about" className="img-fluid"/>
                            <p>Gold</p>
                        </div>
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/11.jpg" alt="about" className="img-fluid"/>
                            <p>Manganese</p>
                        </div>
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/8.jpg" alt="about" className="img-fluid"/>
                            <p>Chrome Ore</p>
                        </div>
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/3.jpg" alt="about" className="img-fluid"/>
                            <p>Grains</p>
                        </div>
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/4.jpg" alt="about" className="img-fluid"/>
                            <p>Energy</p>
                        </div>
                        <div className="col-sm12 col-md-2 col-lg-2 text-center">
                            <img src="assets/images/about/5.jpg" alt="about" className="img-fluid"/>
                            <p>LokNVar Beef</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="servicesCarousel" className="services services-carousel pt-120 pb-90 bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                            <div className="heading heading-3 text-center mb-40">
                                <Link to="/who-we-are" className="heading__subtitle">What we do</Link>
                                <h2 className="heading__title">As a Trading company whose origins trace back to Africa, we aim to challenge the dominance of large commodity trading companies operating in Africa.</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="carousel owl-carousel carousel-dots" data-slide="3" data-slide-md="2"
                                 data-slide-sm="1"
                                 data-autoplay="true" data-nav="false" data-dots="true" data-space="30" data-loop="true"
                                 data-speed="700">
                                <div className="service-item">
                                    <div className="service__img">
                                        <img src="assets/images/services/2.jpg" alt="service" className="img-fluid"/>
                                    </div>

                                    <div className="service__content">
                                        <div className="service__icon"><i className="icon-factory-1"></i></div>
                                        <h4 className="service__title">Mining</h4>
                                        <p className="service__desc">In Zambia, the extraction of copper has dominated
                                            the economy. The firms that have controlled the sector over the years have
                                            been large-scale, mostly foreign.</p>
                                        <Link to="/mining" className="btn btn__secondary btn__link">
                                            <span>Read More</span>
                                            <i className="icon-arrow-right2"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="service-item">
                                    <div className="service__img">
                                        <img src="assets/images/services/3.jpg" alt="service" className="img-fluid"/>
                                    </div>

                                    <div className="service__content">
                                        <div className="service__icon"><i className="icon-gears"></i></div>
                                        <h4 className="service__title">Renewable Energy</h4>
                                        <p className="service__desc">Our strategy is to become a Pan African Independent
                                            Power Producer, IPP, with capabilities to develop, own, procure, construct
                                            and operate renewable energy...</p>
                                        <Link to="/renewable_energy" className="btn btn__secondary btn__link">
                                            <span>Read More</span>
                                            <i className="icon-arrow-right2"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="service-item">
                                    <div className="service__img">
                                        <img src="assets/images/services/4.jpg" alt="service" className="img-fluid"/>
                                    </div>

                                    <div className="service__content">
                                        <div className="service__icon"><i className="icon-eco"></i></div>
                                        <h4 className="service__title">Logistics</h4>
                                        <p className="service__desc">We cannot move physical commodities from where they
                                            are produced to where they are consumed without an effective Logistics and
                                            distribution system, especially in Africa...</p>
                                        <Link to="/logistics" className="btn btn__secondary btn__link">
                                            <span>Read More</span>
                                            <i className="icon-arrow-right2"></i>
                                        </Link>
                                    </div>

                                </div>

                                <div className="service-item">
                                    <div className="service__img">
                                        <img src="assets/images/services/5.jpg" alt="service" className="img-fluid"/>
                                    </div>

                                    <div className="service__content">
                                        <div className="service__icon"><i className="icon-industrial-robot"></i></div>
                                        <h4 className="service__title">Agriculture</h4>
                                        <p className="service__desc">We have a team of experts that identifies and
                                            selects small-scale farmers who are contracted to the company on an
                                            outgrower scheme. We empower these small-scale...</p>
                                        <Link to="/agriculture" className="btn btn__secondary btn__link">
                                            <span>Read More</span>
                                            <i className="icon-arrow-right2"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="service-item">
                                    <div className="service__img">
                                        <img src="assets/images/services/1.jpg" alt="service" className="img-fluid"/>
                                    </div>

                                    <div className="service__content">
                                        <div className="service__icon"><i className="icon-tank-1"></i></div>
                                        <h4 className="service__title">Trading</h4>
                                        <p className="service__desc">Currently, we are operating from two countries;
                                            Zambia and South Africa and we are backed by a large network of primary
                                            producers and processors.</p>
                                        <Link to="/trading" className="btn btn__secondary btn__link">
                                            <span>Read More</span>
                                            <i className="icon-arrow-right2"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about1" className="about about-1 pt-100 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 offset-md-4 text-center">
                            <img src="assets/images/about/LokNVar.png" alt="about img" className=""/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                            <div className="heading heading-2 mt-50">
                                <p className="heading__desc">LokNVar dairy and meat products is a registered trademark
                                    for our dairy, poultry and meat products. Beef is a crucial part of a healthy,
                                    nutritional diet. It is a rich source of protein and contains the nine essential
                                    amino acids that the human body cannot produce. Protein serves as the building
                                    blocks for the body's muscles, connective tissue, bones, brain, blood, skin, hair
                                    and nails. Your immune system is also dependent on protein.</p>
                            </div>
                            <Link to="/Loknvar" className="btn btn__default btn__bordered">Read More</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about1" className="about about-1 pt-100 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-7 text-center">
                            <img src="assets/images/about/pharmaceutical.jpeg" alt="about img" className=""/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-5 my-auto">
                            <div className="heading heading-2"><h2 className="heading__title">Transcon Pharmaceutical Limited</h2>
                                <p className="heading__desc">Transcon Pharmaceutical Limited is a subsidiary of Transcon Global whose goal is to improve healthcare outcomes for patients in Zambia through the supply and distribution of affordable pharmaceutical products.</p>
                            </div>
                            <Link to="/pharmaceutical" className="btn btn__default btn__bordered">Read More</Link>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Home;
