import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurEdgeOverCompetitor() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/box.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Edge over Competitors</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edge over Competitors
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Edge over Competitors</h5>
                                        <p className="text__block-desc">Our ability to gather information from remote
                                            supply markets gives us an edge over our competitors. While many large
                                            commodity-trading companies view their size as a barrier to entry for
                                            smaller trading companies, we view our size as an advantage over them as we
                                            are capable of reaching and penetrating remote areas they cannot.
                                            As a result, we have access to valuable market information bigger
                                            competitors do not have access to in the process giving us an edge over
                                            them. We combine the core competencies of marketing, sourcing and logistics
                                            with the knowledge and insights gained from sustainable remote supplier
                                            relationships. It is these relationships combined with logistical
                                            capabilities that enable us to source physical commodities and deliver them
                                            to locations where they are demanded, thereby benefiting both our suppliers
                                            and customers.
                                        </p>
                                        <p className="text__block-desc">We may not be viewed as “A class Suppliers” in
                                            this competitive and dynamic world of commodity trading. However, we treat
                                            each customer with “A class” service thereby leaving a footprint that will
                                            eventually lead to our customers labeling us as “A-class suppliers” as we
                                            believe in earning our place in a fair and transparent manner.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default OurEdgeOverCompetitor;
