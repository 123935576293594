import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurHistory() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/meats.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our History</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our History</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">History</h5>
                                        <p className="text__block-desc">Transcon Global was formed in February of 2015
                                            prompted by our desire to export beef to Angola. Our extensive feasibility
                                            study of the
                                            market revealed an enormous lack of quality in beef products our competitors
                                            offered. Angola was a tough market for a new entrant and accordingly, we
                                            were not
                                            spared from challenges and risks that come with commodity trading. From
                                            Forex, to complicated customs procedures and language barrier, we rose above
                                            the challenges due to our belief in our core values.</p>
                                        <p className="text__block-desc">Within the short period we have been in
                                            existence, we have acquired essential expertise in the trading of Minerals,
                                            Grains, Oilseeds, Meat, Poultry and Energy. We have been successful
                                            in establishing business and personal relationships with key producers and
                                            processors, especially those in the small-scale bracket.</p>
                                        <p className="text__block-desc">These relationships have been enabling us to
                                            slowly place ourselves in a position of becoming reliable partners in global
                                            Trade.
                                            Having global connections with an extensive and remote network of producers
                                            and processors who share our belief strengthens our ability to originate,
                                            source, store and deliver commodities from a remote place of plenty to an
                                            accessible place of scarcity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default OurHistory;
