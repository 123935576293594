import React, {Fragment, useEffect} from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import Header from "../components/Header";

function Travvc() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/travvc.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Transcon Virtue Value Chain</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">travvc</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="text__block mb-40"><h5 className="text__block-title">Transcon Virtue Value Chain</h5>
                                <p className="text__block-desc">As the COVID 19 takes its toll on global
                                economies, there are a number of possible futures, all dependent on how governments and
                                society respond to coronavirus and its economic aftermath.&nbsp;The way to do business
                                will be fundamentally changed by the pandemic.&nbsp;More business will be done virtually
                                with less or no physical contact between value chain players.</p>

                                <p className="text__block-desc">Travvc is an innovative and transparent technological
                                business platform revolutionizing the flow of hard and soft commodities in the value
                                chains from production and processing to distribution.</p>

                                <p className="text__block-desc">The platform aims to engage and link smallholders in
                                agriculture and mining sectors to local, regional and export markets via an app
                                installed on a smartphone, computer or tablet. The physical movement of the commodities
                                is handled by transporters registered on the App, at market rates and with whom Travvc
                                has agreements. </p>

                                <p className="text__block-desc">Different value chain players such
                                as farmers, artisanal small-scale miners, producers, manufacturers, processors,
                                distributors and logistics firms are brought together by the platform and efficiently
                                conduct value-added based business activities without any physical contact or direct
                                communication, from production to consumption including making and receiving of
                                payments. Payments to both suppliers and transporters are made via our digital platform
                                in various forms such as mobile money, visa, PayPal, debit or credit card. </p>

                                <p className="text__block-desc">Value Chain players can visibly see the flow of the
                                commodities in the value chain from the producer to the consumer. Travvc Trade directly
                                controls the physical value chain and our role is to source, store, blend, process,
                                package and distribute physical materials virtually via the remote participation of
                                different categories of value chain players.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Travvc;
