import React, {Fragment, useEffect, useState} from 'react';
import Footer from '../components/Footer';
import {Link} from "react-router-dom";
import Header from "../components/Header";

function Contact(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    const { country } = props.match.params;

    const render_contact = () => {
        if(country === "za") {
            return (
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="contact-panel mb-40">
                        <h6 className="contact__panel-title">South Africa Trading Office</h6>
                        <ul className="contact__panel-list list-unstyled">
                            <li><i className="fa fa-map-marker"></i><span>RSA Trading office. No. 2 Sandton Drive, Parkmore, Sandton 2196</span></li>
                            <li><i className="fa fa-envelope"></i><span>Email: info@transconglobal.afica</span></li>
                        </ul>
                    </div>
                </div>
            );
        }

        return(
            <div className="col-sm-10 col-md-6 col-lg-4">
                <div className="contact-panel mb-40">
                    <h6 className="contact__panel-title">Zambia Head Office</h6>
                    <ul className="contact__panel-list list-unstyled">
                        <li><i className="fa fa-map-marker"></i><span>Head Office No 9, Elm Road, Woodlands Lusaka </span></li>
                        <li><i className="fa fa-envelope"></i><span>Email: info@transconglobal.afica</span></li>
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/contacts.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Contact</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Contact</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact1" className="contact pt-120 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-6 col-lg-4">
                            <div className="contact-panel mb-40">
                                <h6 className="contact__panel-title">Zambia Head Office</h6>
                                <ul className="contact__panel-list list-unstyled">
                                    <li><i className="fa fa-map-marker"></i><span>Head Office No 9, Elm Road, Woodlands Lusaka </span></li>
                                    <li><i className="fa fa-envelope"></i><span>Email: info@transconglobal.afica</span></li>
                                </ul>
                            </div>

                            <div className="contact-panel mb-40">
                                <h6 className="contact__panel-title">South Africa Trading Office</h6>
                                <ul className="contact__panel-list list-unstyled">
                                    <li><i className="fa fa-map-marker"></i><span>RSA Trading office. No. 2 Sandton Drive, Parkmore, Sandton 2196</span></li>
                                    <li><i className="fa fa-envelope"></i><span>Email: info@transconglobal.afica</span></li>
                                </ul>
                            </div>

                            <div className="contact-panel mb-40">
                                <h6 className="contact__panel-title">UAE Trading Office</h6>
                                <ul className="contact__panel-list list-unstyled">
                                    <li><i className="fa fa-map-marker"></i><span>Office No 22, Ontario Tower, Business Bay, Dubai, UAE</span></li>
                                    <li><i className="fa fa-envelope"></i><span>Email: info@transconglobal.afica</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <form>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group"><input type="text" className="form-control"
                                                                           placeholder="Name"/></div>
                                    </div>

                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group"><input type="email" className="form-control"
                                                                           placeholder="Email"/></div>
                                    </div>

                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group"><input type="nuber" className="form-control"
                                                                           placeholder="Phone Nuber"/></div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder="Message"></textarea>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <button type="submit"
                                                className="btn btn__secondary btn__hover3 btn__block">Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Contact;
