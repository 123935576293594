import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import Header from "../../components/Header";

function Trading() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/global.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Trading</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/what-we-do">what we do</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Trading</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar mb-30">
                                <div className="widget widget-categories">
                                    <h5 className="widget__title">What We Do</h5>
                                    <div className="widget-content">
                                        <ul className="list-unstyled">
                                            <li><Link to="/trading" className="active">Trading</Link></li>
                                            <li><Link to="/mining">Mining</Link></li>
                                            <li><Link to="/renewable-energy">Renewable Energy</Link></li>
                                            <li><Link to="/logistics">Logistics</Link></li>
                                            <li><Link to="/agriculture">Agriculture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">As a Trading company whose origins trace back
                                            to Africa, we aim to challenge the dominance of large commodity trading
                                            companies operating in Africa. </h5>
                                        <p className="text__block-desc">Currently, we are operating in three countries;
                                            Zambia, South Africa and more recently we have opened a full-fledged trading
                                            office in the United Arab Emirates, UAE with the sole aim of importing and
                                            exporting commodities as well as Fast-Moving Consumer Goods (FMCG) between
                                            Africa and the rest of the world via our UAE office. </p>
                                        <p className="text__block-desc">We are backed by a large network of primary
                                            producers and processors . Our producers, who are mostly, small scale, are
                                            at the Centre of our focus. As a Trading company whose origins trace back to
                                            Africa, we aim to challenge the dominance of large commodity trading
                                            companies operating in Africa.</p>
                                        <p className="text__block-desc">We source physical commodities from shunned
                                            remote places, store, blend and deliver them reliably, efficiently and
                                            responsibly anywhere, to our customers. We are the much required market link
                                            between producers and consumers. We add value to remote places we operate in
                                            by uplifting lives through trade, however one life at a time.</p>
                                        <p className="text__block-desc">Our resilient team of logistics experts is able
                                            to find transportation means and ways to reach even the remotest places,
                                            source physical commodities and deliver them to places they are needed the
                                            most.</p>
                                        <p className="text__block-desc">We actively trade in Minerals (Industrial, Energy, Precious, Semi-precious), Grains and Oilseeds (Wheat, Maize, Sunflower, Soybeans, barley and Sorghum), Meat (Beef, lamb, goat and Pork) Poultry (Chickens and Turkey), Energy products (Fossil Fuels, Biofuels, Coal and Gas) , Fertilisers and Chemicals and Fast-Moving Consumer Goods (FMCG).</p>
                                    </div>

                                    <div className="video-1 mb-60">
                                        <div className="bg-img"><img src="assets/images/video/5.jpg" alt="background"/></div>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <p className="text__block-desc">From our network of producers, we have a wider
                                            origin and quality and always ensure our products are stored and delivered
                                            in the best possible manner.</p>
                                        <p className="text__block-desc">We take pride in delivering high quality
                                            products to our clients. To achieve this, we take time to inspect facilities
                                            of our producers and processors from
                                            the place of production all the way through the packaging process in order
                                            to ensure the product we take to the market is of their highest quality </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: 30}}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="projects-carousel-2 carousel owl-carousel carousel-arrows"
                                         data-slide="2" data-slide-md="2"
                                         data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="false"
                                         data-space="30"
                                         data-loop="true" data-speed="800">
                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/1.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/2.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/3.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/4.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/7.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/8.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/9.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/10.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/11.jpg" alt="project img"/>
                                            </div>
                                        </div>

                                        <div className="project-item">
                                            <div className="project__img">
                                                <img src="assets/images/case-studies/grid/12.jpg" alt="project img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Trading;
