import React from 'react';
import {Link} from "react-router-dom";

function Slider() {
    return (
        <section id="slider2" className="slider slider-2 text-center">
            <div className="carousel owl-carousel carousel-arrows carousel-dots" data-slide="1" data-slide-md="1"
                 data-slide-sm="1" data-autoplay="true" data-nav="true" data-dots="true" data-space="0" data-loop="true"
                 data-speed="3000" data-transition="fade" data-animate-out="fadeOut" data-animate-in="fadeIn">
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/mine.jpg" className="img-fluid" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">We Are</h2>
                                    <p className="slide__desc">a young, dynamic and resilient commodity trading company.</p>
                                    <Link to="/who-we-are" className="btn btn__primary btn__hover2 mr-30">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/blue-stone.jpg" className="img-fluid" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">Your Global Trade Partner</h2>
                                    <p className="slide__desc">We source and supply base Metals, Semi/Precious  Minerals ,Beef, Poultry, Oilseeds, Grains and Energy Products.</p>
                                    <Link to="/who-we-are" className="btn btn__primary">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/stock.jpg" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">Physical Commodities</h2>
                                    <p className="slide__desc">We source and move physical commodities from a surplus place of supply to a place of demand.</p>
                                    <Link to="/who-we-are" className="btn btn__primary btn__hover2 mr-30">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/plant.jpg" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">Uplifting Lives</h2>
                                    <p className="slide__desc">We see trade as a powerful and effective tool in uplifting millions of lives in our core markets, however one life at a time.</p>
                                    <Link to="/who-we-are" className="btn btn__primary btn__hover2 mr-30">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/maize.jpg" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">Our Desire</h2>
                                    <p className="slide__desc">To use trade in less mature and developing markets to help uplift living standards and empower all those living there.</p>
                                    <Link to="/who-we-are" className="btn btn__primary btn__hover2 mr-30">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="assets/images/sliders/culture.jpg" alt="slide img"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 offset-xl-1">
                                <div className="slide__content">
                                    <h2 className="slide__title">Our Focus</h2>
                                    <p className="slide__desc">To advance reliable trade in an open and transparent manner in less mature and developed markets.</p>
                                    <Link to="/who-we-are" className="btn btn__primary btn__hover2 mr-30">More About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Slider;
