import React, {Fragment, useEffect} from 'react';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import Header from "../components/Header";

function Pharmaceutical() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/pharmaceutical.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Transcon Pharmaceutical Limited</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">subsidiaries</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="text__block mb-40">
                                <img src="assets/images/page-titles/tp-logo.jpg" height="170" style={{marginBottom: 5}} alt="logo"/>
                                <h5 className="text__block-title">Transcon Pharmaceutical Limited </h5>
                                <p className="text__block-desc">Transcon Pharmaceutical Limited is a subsidiary of
                                    Transcon Global whose goal is to improve healthcare outcomes for patients in Zambia
                                    through the supply and distribution of affordable pharmaceutical products.</p>

                                <p className="text__block-desc">We strongly believe that the affordability of
                                    pharmaceutical products has an enormous positive influence on the health, prosperity
                                    and economic productivity of our country by saving lives, increasing life spans,
                                    reducing suffering, preventing surgeries and shortening hospital stays. Access to
                                    affordable quality medicine can eliminate deadly diseases and bring other
                                    life-threatening conditions under control.</p>

                                <p className="text__block-desc">With our full-fledged office in the United Arab
                                    Emirates, we have access to international manufacturers of quality and affordable
                                    pharmaceutical products and medical supplies. We offer a broad portfolio and full
                                    line of generic and brand name pharmaceutical products including tablets, capsules,
                                    creams, ointments, liquids, vaccines, and injectables product, suspensions, oral
                                    solutions, elixirs, syrups, and liquids. Our generic products adhere to strict ZAMRA
                                    compliance. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default Pharmaceutical;
