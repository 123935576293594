import React from 'react';
import logo from '../logo.svg';
import { Link } from "react-router-dom";

function Header() {
    return (
        <header id="header" className="header header-transparent">
            <nav className="navbar navbar-expand-lg sticky-navbar">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src='assets/images/logo/logo-light.png' className="logo-light" alt="logo" height="65"/>
                            <img src='assets/images/logo/logo-dark.png' className="logo-dark" alt="logo" height="65"/>
                    </Link>
                    <button className="navbar-toggler" type="button">
                        <span className="menu-lines"><span></span></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavigation">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav__item">
                                <Link to="/" className="nav__item-link">Home</Link>
                            </li>
                            <li className="nav__item with-dropdown">
                                <Link to="who-we-are" className="dropdown-toggle nav__item-link">Who we are</Link>
                                <i className="fa fa-angle-right" data-toggle="dropdown"></i>
                                <ul className="dropdown-menu">
                                    <li className="nav__item"><Link to="our-company" className="nav__item-link">Our
                                        company</Link></li>
                                    <li className="nav__item"><Link to="our-history" className="nav__item-link">Our
                                        History</Link></li>
                                    <li className="nav__item"><Link to="our-desire-focus" className="nav__item-link">Our
                                        Desire & Focus</Link></li>
                                    <li className="nav__item"><Link to="our-vision-mission" className="nav__item-link">Our
                                        Vision & Mission</Link></li>
                                    <li className="nav__item"><Link to="our-business-model" className="nav__item-link">Our
                                        Business Model</Link></li>
                                    <li className="nav__item"><Link to="our-edge-over-competitor"
                                                                    className="nav__item-link">Our Edge Over
                                        Competitors</Link></li>
                                    <li className="nav__item"><Link to="our-capacity" className="nav__item-link">Our
                                        Capacity</Link></li>
                                    <li className="nav__item"><Link to="working-with-us" className="nav__item-link">Working
                                        with us</Link></li>
                                </ul>
                            </li>
                            <li className="nav__item with-dropdown">
                                <Link to="#" className="dropdown-toggle nav__item-link">What we do</Link>
                                <i className="fa fa-angle-right" data-toggle="dropdown"></i>
                                <ul className="dropdown-menu">
                                    <li className="nav__item"><Link to="trading"
                                                                    className="nav__item-link">Trading</Link></li>
                                    <li className="nav__item"><Link to="mining" className="nav__item-link">Mining</Link>
                                    </li>
                                    <li className="nav__item"><Link to="renewable-energy" className="nav__item-link">Renewable
                                        Energy</Link></li>
                                    <li className="nav__item"><Link to="logistics"
                                                                    className="nav__item-link">Logistics</Link></li>
                                    <li className="nav__item"><Link to="agriculture"
                                                                    className="nav__item-link">Agriculture</Link></li>
                                    {/*<li className="nav__item"><Link to="/procedures" className="nav__item-link">Procedures</Link></li>*/}
                                </ul>
                            </li>
                            <li className="nav__item">
                                <Link to="sustainability" className="nav__item-link">Sustainability</Link>
                            </li>
                            <li className="nav__item">
                                <Link to="our-supply-chain" className="nav__item-link">Our Supply Chain</Link>
                            </li>
                            <li className="nav__item with-dropdown">
                                <Link to="#" className="dropdown-toggle nav__item-link">Subsidiaries</Link>
                                <i className="fa fa-angle-right" data-toggle="dropdown"></i>
                                <ul className="dropdown-menu">
                                    <li className="nav__item"><Link to="pharmaceutical"
                                                                    className="nav__item-link">Pharmaceutical</Link>
                                    </li>
                                    <li className="nav__item"><Link to="loknvar"
                                                                    className="nav__item-link">LokNVar</Link></li>
                                </ul>
                            </li>
                            <li className="nav__item">
                                <Link to="contact" className="nav__item-link">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
