import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurCapacity() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/ship.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Capacity</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Capacity</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Capacity</h5>
                                        <p className="text__block-desc">At Transcon Global, we are always looking ahead.
                                            We understand most of the challenges and risks involved in commodity
                                            trading.
                                        </p>
                                        <p className="text__block-desc">We also do not see ourselves as the way you see
                                            us. We do not gauge ourselves by what we are today but by what we want to
                                            become tomorrow. Hence we are constantly working hard to satisfy the needs
                                            of both our producers/Processors and customers.
                                        </p>
                                        <p className="text__block-desc">Our capacity is not measured by the size or
                                            number of offices, globally but by the vast global knowledge of the
                                            commodity trading industry and our desire to become world class trading
                                            partners. We do not judge ourselves by the tonnages we move across the
                                            oceans but by the size of the smile we leave on a satisfied
                                            producer/processor and Customer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Fragment>
    );
}

export default OurCapacity;
