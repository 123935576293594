import React from 'react';
import './App.css';
import Home from './pages/Home';
import WhoWeAre from "./pages/WhoWeAre";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import OurCompany from "./pages/who-we-are/OurCompany";
import OurHistory from "./pages/who-we-are/OurHistory";
import OurDesireFocus from "./pages/who-we-are/OurDesireFocus";
import OurVisionMission from "./pages/who-we-are/OurVisionMisson";
import OurBusinessModel from "./pages/who-we-are/OurBusinessModel";
import OurEdgeOverCompetitor from "./pages/who-we-are/OurEdgeOverCompetitor";
import OurCapacity from "./pages/who-we-are/OurCapacity";
import WorkingWithUs from "./pages/who-we-are/WorkingWithUs";
import Trading from "./pages/what-we-do/Trading";
import Mining from "./pages/what-we-do/Mining";
import RenewableEnergy from "./pages/what-we-do/RenewableEnergy";
import Logistics from "./pages/what-we-do/Logistics";
import Agriculture from "./pages/what-we-do/Agriculture";
import Sustainability from "./pages/Sustainability";
import Contact from "./pages/Contact";
import OurSupplyChain from "./pages/OurSupplyChain";
import Travvc from "./pages/Travvc";
import Legal from "./pages/Legal";
import Pharmaceutical from "./pages/Pharmaceutical";
import Loknvar from "./pages/Loknvar";

function App() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <div className="wrapper">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/who-we-are" component={WhoWeAre} />
                    <Route exact path="/travvc" component={Travvc} />
                    <Route exact path="/legal-disclaimer" component={Legal} />
                    <Route exact path="/our-company" component={OurCompany} />
                    <Route exact path="/our-history" component={OurHistory} />
                    <Route exact path="/our-desire-focus" component={OurDesireFocus} />
                    <Route exact path="/our-vision-mission" component={OurVisionMission} />
                    <Route exact path="/our-business-model" component={OurBusinessModel} />
                    <Route exact path="/our-edge-over-competitor" component={OurEdgeOverCompetitor} />
                    <Route exact path="/our-capacity" component={OurCapacity} />
                    <Route exact path="/working-with-us" component={WorkingWithUs} />

                    <Route exact path="/trading" component={Trading} />
                    <Route exact path="/pharmaceutical" component={Pharmaceutical} />
                    <Route exact path="/loknvar" component={Loknvar} />
                    <Route exact path="/mining" component={Mining} />
                    <Route exact path="/renewable-energy" component={RenewableEnergy} />
                    <Route exact path="/logistics" component={Logistics} />
                    <Route exact path="/agriculture" component={Agriculture} />
                    <Route exact path="/sustainability" component={Sustainability} />
                    <Route exact path="/our-supply-chain" component={OurSupplyChain} />
                    <Route exact path="/contact" component={Contact} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
