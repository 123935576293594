import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function OurVisionMission() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/culture.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Vision & Mission</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Vision & Mission</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Vision</h5>
                                        <p className="text__block-desc">To be a highly ethical, fair, transparent and
                                            efficient commodity supply global partner and continually exceed market
                                            expectations with premium quality products.
                                        </p>
                                        <p className="text__block-desc">We aim to achieve our vision by establishing a
                                            wide network of supply and demand base as well as adopting highly efficient
                                            distribution methods, modern storage techniques and practices without
                                            compromising internationally accepted standards of hygiene and quality and
                                            lastly by placing progress in front of profits.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Our Mission</h5>
                                        <p className="text__block-desc">At Transcon Global, we are always looking ahead.
                                            We understand most of the challenges and risks involved in commodity
                                            trading.
                                        </p>
                                        <p className="text__block-desc">We aim to achieve this by obtaining grass-root
                                            information both from the places of supply, however remote, and demand and
                                            using this information in uplifting standards of living for our peasant
                                            producers and helping our commercial producers remain viable and continue to
                                            achieve sustainable growth in the long term.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default OurVisionMission;
