import React, {Fragment, useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";

function WorkingWithUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/sliders/plane.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Working with us</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">who we are</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Working with us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h5 className="text__block-title">Working with us</h5>
                                        <p className="text__block-desc">We have a strong, experienced and proactive
                                            management team whose entrepreneurial skills, culture, passion and
                                            commitment have been
                                            guiding us through tremendous growth. Our management team has earned an
                                            enormous amount of knowledge from both past failures and successes,
                                            especially failures. </p>
                                        <p className="text__block-desc">We take pride in the efficiency of our always
                                            on-time delivery system. This system has proven its reliability to both our
                                            small and local customers to large international ones. </p>
                                        <p className="text__block-desc">Our ability to export in bulk is made possible
                                            through our international alliances with various producers, processors and
                                            traders as well as shipping companies and also through
                                            our stock management system that tracks all products from the supplier to
                                            the customer, ensuring full accountability and traceability of the highest
                                            standards. Our management is involved in every aspect of the
                                            business from sourcing to logistics and up to the place of delivery. </p>
                                        <p className="text__block-desc">We provide a fair and transparent platform of
                                            solutions to our suppliers and buyers. We are committed to building and
                                            maintaining long-term relationships with all our suppliers and customers
                                            irrespective of size. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default WorkingWithUs;
