import React, {Fragment, useEffect} from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Header from "../components/Header";

function OurSupplyChain() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = false;
        document.body.appendChild(script);
    }, []);

    return (
        <Fragment>
            <Header/>
            <section id="pageTitle" className="page-title page-title-layout4 bg-overlay bg-parallax text-center">
                <div className="bg-img"><img src="assets/images/page-titles/chain.jpg" alt="background"/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle__heading">Our Supply Chain</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Our Supply Chain</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section id="textContentSection" className="text-content-section pt-120  pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block mb-40">
                                        <h5 className="text__block-title">We supply our commodities through our physical and
                                            virtual integrated supply chains, sourcing and originating from small and remote
                                            areas of  production to large areas of demand.</h5>
                                        <p className="text__block-desc">We supply our commodities through our physical and
                                            virtual integrated supply chains, sourcing and originating from small and remote
                                            areas of  production to large areas of demand. The size of our producers doesn't
                                            matter, what matters is the final aggregation. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text__block">
                                        <h6>Grains and Oilseeds</h6>
                                        <p>We source our Grains and Oilseeds on a seasonal basis from small-scale farmers and
                                            traders in Zambia, Malawi and other African countries. We have satellite depots
                                            located in identified remote  areas where commodities are delivered and payments made
                                            immediately. From there, the commodities are transported to our central storage facility
                                            for sorting, blending and grading. </p>
                                        <br/>

                                        <h6>Beef and Poultry</h6>
                                        <p>We source and buy beef and poultry from Zambia,  South Africa, Argentina and Brazil and export
                                            to markets such as Angola and Democratic Republic of Congo,  DRC . We have formed great relationships
                                            with leading suppliers who enable us source the freshest A-grade meat supply available on the market.
                                        </p>

                                        <br/>
                                        <h6>Manganese</h6>
                                        <p>We source and buy high grade  Manganese from Artisanal Small-Scale Mines in Zambia (Mansa, Mkushi and Serenje).
                                            Preliminary testing is conducted on site before the Manganese is delivered to our location points. We are also
                                            constantly forging partnerships with Artisanal Small-Scale Mines with high potential. We provide support in form
                                            of equipment and finance and sign off-take agreements. Our Manganese is sorted, blended and washed before exporting
                                            the commodity to China, India and South Africa.
                                        </p>

                                        <br/>
                                        <h6>Copper Cathodes</h6>
                                        <p>We source and buy Non-registered LME copper cathodes from the Democratic Republic of Congo, DRC. We have forged partnerships
                                            with credible, reliable, honest  and licensed mining , mineral processing and trading firms to support our supply chain.
                                            We understand that Trading in Copper Cathodes is highly complex and risky as such we strictly conduct our business in Bonded
                                            Warehouses in Zambia or any port of loading in the SADC region.
                                        </p>

                                        <br/>
                                        <h6>Cobalt</h6>
                                        <p>We source and buy Grade 1 and 2 Cobalt in powder form from Zambia and Democratic Republic of Congo, DRC. We have forged
                                            partnerships with credible, reliable, honest  and licensed mining , mineral processing and trading firms to support our supply chain.
                                            We also source and buy from Small- Scale Mines , ASM in both Zambia and Democratic Republic of Congo, DRC. For any commodities sourced
                                            from DRC, business takes places in Bonded Warehouses in Zambia.
                                        </p>

                                        <br/>
                                        <h6>Semi- Precious Minerals</h6>
                                        <p>We source and buy Gold from Artisanal Small- Scale Mines , ASM in Zambia,  Zimbabwe and Democratic Republic of Congo,  DRC. Gold testing is
                                            conducted on site to mitigate risk and payments made instantly via safe payment systems. We export our Gold to various countries across the globe.
                                        </p>

                                        <br/>
                                        <h6>Other Base Matals</h6>
                                        <p>We  source and buy other base metals such as Lead and  Zinc from Zambia and Democratic Republic of Congo, DRC. We are constantly forging partnerships
                                            with Artisanal Small-Scale Mines with high potential. There are a lot of mining and exploration licenses that have been issued to various Small-Scale
                                            mines around our focus countries that we try to leverage for mutual benefits.
                                        </p>

                                        <br/>
                                        <h6>Semi- Precious Minerals</h6>
                                        <p>We are licensed to trade in Germstones such as emarald, aquamarine,  tourmaline and amethyst. We source and buy from Artisanal Small-Scale mines in Zambia
                                            and Democratic Republic of Congo,  DRC and export to various countries across the globe.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <br/>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="features-list features-list-layout3 mb-30">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <p className="text__block-desc">Our Supply Chain Process:</p>
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Sourcing</h4>
                                                        <p className="feature__desc">We source grains, oilseeds, poultry and minerals  from small scale producers in remote areas of Zambia and the Democratic republic of Congo (DRC).</p>
                                                    </div>
                                                </div>

                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Storage</h4>
                                                        <p className="feature__desc">We collect our commodities  from remote areas and store them with maximum care.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Sorting and blending.</h4>
                                                        <p className="feature__desc">Once sourced and produced, we wash, blend and sort our commodities efficiently.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Trading</h4>
                                                        <p className="feature__desc">We connect supply and demand through our physical and Virtual trading platforms.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="feature-item feature-list-item">
                                                    <div className="feature__content">
                                                        <h4 className="feature__title">Transportation and Distribution</h4>
                                                        <p className="feature__desc">With our large transportation network, we deliver our commodities to customers with maximum efficiency.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </Fragment>
    );
}

export default OurSupplyChain;
